import axios from 'axios'

// const api = axios.create({
//     baseURL: 'http://localhost:8000/api/v01',
//     withCredentials: false,
//     headers: {
//         Accept: 'application/json',
//         'Content-type': 'application/json',
//         // 'Authorization': 'Bearer ' + localStorage.getItem('token')
        
//     }
// })

// axios.defaults.baseURL = 'http://localhost:8000/api/v01'
// axios.defaults.baseURL = 'https://healthsuccessinternationalapi.onrender.com/api/v01'
// axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')


const Api = axios.create({
    baseURL: 'http://localhost:8000/api/v01'
})

// Api.interceptors.request.use(request => {
//     let token = localStorage.getItem('token')
//     if (token !== null) {
//         console.log(token)
//         request.headers.Authorization = 'Bearer '+token
//     }

//     return request
// })


export default {

    login(loginInfo){
        return new Promise((resolve, reject) => {
            
            Api.post('/auth/login/', loginInfo).then( response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
            
        })
    },


    logout(token){
        return new Promise((resolve, reject) => {
            Api.post('/auth/logout/', {key: token}).then(response => {
                localStorage.clear()
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },


    getCountries() {
        return new Promise((resolve, reject) => {
            Api.get('/country/').then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    getCities() {
        return new Promise((resolve, reject) => {
            Api.get('/city/').then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    getBureaux() {
        return new Promise((resolve, reject) => {
            Api.get('/bureau/').then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    getBureau(bureau_id){
        return new Promise((resolve, reject) => {
            Api.get('/bureau/'+bureau_id+'/').then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })

    },


    getEquilibres(){
        return new Promise((resolve, reject) => {
            Api.get('/equilibre/').then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },


    getReferrals(){
        return new Promise((resolve, reject) => {
            Api.get('/referral/').then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },


    // Post actions
    // ======================
    updateMember(update_member){
        return new Promise((resolve, reject) => {
            Api.put('/member/', update_member).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    postBureau(bureau) {
        return new Promise((resolve, reject) => {
            Api.post('/bureau/', bureau).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },

    updateBureau(update_bureau){
        return new Promise((resolve, reject) => {
            Api.put('/bureau/', update_bureau).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },


    postPayment(payment_data) {
        return new Promise((resolve, reject) => {
            Api.post('/payment/', payment_data).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },


    // Delete Actions
    // ===============================
    deleteMember(member_id){
        return new Promise((resolve, reject) => {
            Api.delete('/member/'+ member_id+'/').then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    }

}