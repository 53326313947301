<template>
    <section>
        <div class="bg-[url('@/assets/buildingPhoto.jpg')] bg-fixed bg-opacity-50 w-100% h-100% text-xl text-white">
        <div class="bgblack h-full w-full">
        <header class="text-center mx-auto mb-2 pt-5 lg:px-20">
            <h2 class="text-3xl leading-normal mb-2 font-bold text-white">About us</h2>
            <p class="text-white font-light leading-relaxed text-xl mx-auto">Get to know who we are and our mission</p>
        </header>

        <hr class="h-1 bg-yellow-500 w-20 mx-auto ">

            <div class="text-center place-self-center py-12 px-2 md:px-16">
            Established  by Dr. Alexander Wilson in 2020, Health Success International is a Thailand-based company. <br />
            In its concern to promote the nutritional health of the world population, Health Success International has found it appropriate to supplement food naturally, especially since food insecurity and poor nutrition are believed to be at the root of many diseases.<br />
            As some people do not respect the nutritional norms, as dictated by the WHO, Health Success International has chosen as a priority the nutritional education and the care of people with health problems.      
            </div>
        </div>
        </div>
    </section>

</template>

<style>
.bgblack{
    background:rgb(0,0,0,0.3);
}
</style>

<script>

export default{
name: 'aboutUsSection',
data(){
    return{

    }
},
}
</script>