/** Import des modules nécessaires */
import Axios from "./caller.service"



let login = (payload) => {
    return Axios.post('auth/login/', payload)
}


let saveToken = (token) => {
    localStorage.setItem('token', token)
}



let getToken = () => {
    return localStorage.getItem('token')
}


let logout = () => {
    Axios.post('auth/logout/')
    localStorage.removeItem('token')

}


let isLogged = () => {
    let token = localStorage.getItem('token')
    return !!token
}



export const accountService = {
    login, logout, saveToken, getToken, isLogged
}

