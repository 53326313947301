<template>

    <main>

        <!-- Heading start -->
        <header class="text-center mx-auto mb-2 lg:px-20">
            <h2 class="text-3xl leading-normal mb-2 font-bold text-green-600">Get in touch</h2>
            <p class="text-gray-500 leading-relaxed font-light text-xl mx-auto">How can we help you? Just contact us</p>
        </header>
        <!-- End heading -->
        
        <hr class="h-1 bg-yellow-500 w-20 mx-auto mb-8">

        <div class="md:flex md:space-x-8 md:mx-16  mx-4 mb-4 mt-8">
            <div class="hidden md:block w-1/2">
                <img src="@/assets/buildingPhoto.jpg" class="" alt=""/>
            </div>
            
            <div class="w-full md:w-1/2">
                <form class="w-full">

                    <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
                        ENTER YOUR NAME
                    </label>
                    <input class="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Enter your name">
                    </div>

                    <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="mail">
                        ENTER E-MAIL
                    </label>
                    <input class=" border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Enter your e-mail adress">
                    </div>

                    <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                        ENTER MESSAGE
                    </label>
                    <textarea class="border rounded w-full h-16 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Enter your message"></textarea>
                    </div>
                
                    <center class="items-center text-center">
                    <button class="bg-green-600 hover:bg-green-500 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline text-center" type="button">
                        Submit
                    </button>
                    </center>

                </form>
            </div>

        </div>

    </main>

</template>

<script>
export default{
    name:'contactUsSection',
    data(){
        return{

        }
    }
}
</script>