/** Import des modules nécessaires */
import Axios from "./caller.service"


let get = (endpoint) => {
    return Axios.get(endpoint)
}


let post = (endpoint, payload) => {
    return Axios.post(endpoint, payload)
}

let patch = (endpoint, payload) => {
    return Axios.patch(endpoint, payload)
}

let put = (endpoint, payload) => {
    return Axios.put(endpoint, payload)
}

let delete_obj = (endpoint) => {
    return Axios.delete(endpoint)
}


export const memberService = {
    get, post, patch, put, delete_obj
}
