<template >

    <coverPageNavBar class="sticky top-0 z-10"/>

    <div class="h-full relative overflow-hidden">


        <div id="home" class="bg-cover bg-center mb-3 items-center changeBG baner">
            <div class="py-20 grid-cols-3 sm:grid-cols-1 text-white bg-black bg-opacity-50 text-center h-full items-center">

                <img src="@/assets/health_succes_logo.png" class="w-20 mx-auto mt-4 mb-2" alt="">
                <strong class="text-3xl">Health Success International</strong>
                <p class="text-xl mt-3 mb-10 changeTXT"></p>             
				<div class="space-x-12">
                    <button @click="go_to_ogin" class="py-2 px-6 font-medium text-white border border-green-500 rounded-full hover:bg-green-500 hover:text-white transition duration-300">
                        Connexion
                    </button>
					<a href="" class="py-2 px-6 font-medium text-white border border-green-500 rounded-full  hover:text-white hover:bg-green-500 transition duration-300">
                        Get started
                    </a>
                </div>
            </div>
        </div>

        <div>
            <div id="news"> <newsSection/> </div>
            <div id="about"> <aboutUsSection/> </div>
            <div id="products"> <productsSection /> </div>
            <div id="bonus"> <bonusSection/> </div>
            <div id="contact"> <contactUsSection /> </div>
        </div>
        
        
        <coverPageFooter />


    </div>

</template>

<style>
.baner {
    height: 480px;
}

.changeBG{
    animation:changeBG 40s infinite;
    /* background-size: 100%; */
    background-repeat: no-repeat;

}

.changeTXT::before{
    animation : changeTXT 40s infinite ;
    transition:cubic-bezier(0.165, 0.84, 0.44, 1);
    content:'The one who is healthy has everything and enjoys success';
}

@keyframes changeBG{
    0%{background-image:url("@/assets/hero/hsiNaturalHealth.png");}
    25%{background-image:url("@/assets/hero/sheDoctorWithPatient.jpg");background-position: top;}
    50%{background-image:url("@/assets/hero/img.jpg");}
    75%{background-image:url("@/assets/hero/happy-business-people.png");}
    100%{background-image:url("@/assets/hero/hsiNaturalHealth.png");}
}

@keyframes changeTXT{
    0%{content:"Take care of your body, It's the only place you have to live.";}
    25%{content:'He who has health,has hope... and he who has hope, has everything';}
    50%{content:'Collaborate for success';}
    75%{content:'Be healthy and enjoy success with others';}
    100%{content:"Take care of your body, It's the only place you have to live.";}
}
</style>

<script>
import newsSection from '@/components/client_web/newsSection.vue';
import aboutUsSection from '@/components/client_web/aboutUs.vue';
import coverPageNavBar from '@/components/client_web/coverPageNavBar.vue';
import coverPageFooter from '@/components/client_web/coverPageFooter.vue';
import productsSection from '@/components/client_web/productsSection.vue';
import bonusSection from '@/components/client_web/bonusSection.vue';
// import teamSection from '@/components/client_web/teamSection.vue'
import contactUsSection from '@/components/client_web/contactUsSection';

import router from "@/router"

export default {
    name: 'homePage',
    components: {
        newsSection,
        aboutUsSection,
        coverPageNavBar,
        coverPageFooter,
        productsSection,
        bonusSection,
        // teamSection,
        contactUsSection,
    },methods: {
        go_to_ogin(){
            router.replace({name: 'login', force: true})
        },

        global: function () {
            alert("Hello world")
        },
  },
    
 }
</script>