<template>
  <div>
    <router-view/>
  </div>
</template>


<script>
import './assets/css/tailwind.css'

// services
import { memberService } from '@/_services'

export default {
  name: 'App',
  seput(){

  },
  created(){

    memberService.get('auth/user/').then(res => {

    if(res){
      let user = res.data
      this.$store.commit('setLoggedUser', user)

      memberService.get('member/'+user.pk).then(res => {
        if(res){
          this.$store.commit('setLoggedMember', res.data)
        }else{
          memberService.get('bureau/'+user.pk).then(res => {
            this.$store.commit('setLoggedBureau', res.data)
            this.$store.commit('setIsUserLoggin', true)
          })
        }
      })
    }

    })

  }
  
}

</script>

<style src="./assets/css/tailwind.css"/>



