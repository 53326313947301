import { createStore } from 'vuex'
import api_util from '../../utils/api_util.js'


export default createStore({
  state: {

    /**
     * GENERAL STATES
     */
    loggedUser: null,
    isUserLoggin: false,

    loggedBureau: null,

    usernameCreatedError: false,

    // =====================================

    /**
     * CLIENT SIDE STATES
     */
    all_downlines: [], 
    member_team_inscriptions:[],
    memberReferral: [],
    memberEquilibre: [],
    memberBonusVente: [],
    memberPayments: [],
    loggedMember: null,
    // =================================


    /**
     * LOCAL OFFICE STATES
     */
    
    // Equilibre bonus state
    equilibres: [],
    referrals: [],
    members: [],
    inscriptions: [],
    memberDetails: false,
    memberRecord: true,
    currentMember: null,

    // =================================

    /**
     * CENTRAL OFFICE STATES
     */

    // office states
    bureaux: [],
    currentBureau: null,

    // Country states
    countries: [],
    currentCountry: null,

    // City states
    cities: [],
    currentCity: null,

    generatedCodes: [],
    paymentDegrees: [],
    rewardRanges: [],
    currentPaymentDegree: null,
    currentRewardRange: null,
    currentGeneratedCode: null,

    // =================================
    
  },
  getters: {

    members(state){
      return state.members
    },

    loggedMember(state){
      return state.loggedMember
    },


    loggedUser(state) {
      return state.loggedUser
    },

    loggedBureau(state){
      return state.loggedBureau
    },

    inscriptions(state){
      return state.inscriptions
    },

    countries(state){
      return state.countries
    },

    cities(state){
      return state.cities
    },

    equilibres(state){
      return state.equilibres
    },

    referrals(state){
      return state.referrals
    },

    currentCountry(state){
      return state.currentCountry
    },

    currentCity(state){
      return state.currentCity
    },

    bureaux(state){
      return state.bureaux
    },

    currentBureau(state){
      return state.currentBureau
    },

    generatedCodes(state){
      return state.generatedCodes
    },

    currentGeneratedCode(state){
      return state.currentGeneratedCode
    },

    paymentDegrees(state){
      return state.paymentDegrees
    },

    currentPaymentDegree(state){
      return state.currentPaymentDegree
    },

    rewardRanges(state){
      return state.rewardRanges
    },

    currentRewardRange(state){
      return state.currentRewardRange
    },


  },
  mutations: {

    // Client Side mutations
    setAllDownlines(state, value) {
      state.all_downlines = value
    },

    setTeamInscriptions(state,val) {
      state.member_team_inscriptions = val
    },
    
    setMemberReferral(state,val) {
      state.memberReferral = val
    },

    setMemberEquilibre(state,val) {
      state.memberEquilibre = val
    },

    setMemberBonusVente(state,val) {
      state.memberBonusVente = val
    },

    setMemberPayments(state,val){
      state.memberPayments = val
    },

    // =============================

    // Auth mutation
    logout(token){
      api_util.logout(token)
    },

    setLoggedBureau(state, payload){
      state.loggedBureau = payload
    },

    setUsernameCreatedError(state, payload){
      state.usernameCreatedError = payload
    },

    setLoggedUser(state, payload){
      state.loggedUser = payload
    },

    setLoggedMember(state, payload){
      state.loggedMember = payload
    },

    setIsUserLoggin(state, payload){
      state.isUserLoggin = payload
    },

    setMemberDetails(state, payload){
      state.memberDetails = payload
    },

    setMemberRecord(state, payload){
      state.memberRecord = payload
    },

    setCurrentMember(state, payload){
      state.currentMember = payload
    },

    // Equilibre bonus mutations
    setEquilibres(state, equilibres){
      state.equilibres = equilibres.reverse()
    },

    // Referral bonus mutations
    setReferrals(state, referrals){
      state.referrals = referrals.reverse()
    },

    // Member mutations
    setInscriptions(state, inscriptions) {
        state.inscriptions = inscriptions.reverse();
    },

    setInscription(state, inscription) {
        state.inscriptions.unshift(inscription)
    },

    setMembers(state, members) {
      state.members = members.reverse();
    },

    setMember(state, member) {
        state.members.unshift(member)
    },

    // Bureau mutations
    setCurrentBureau(state, bureau){
      state.currentBureau = bureau
    },

    setBureaux(state, bureaux){
      state.bureaux = bureaux.reverse()
    },

    setBureau(state, bureau){
      state.bureaux.push(bureau)
    },

    // Country mutations
    setCurrentCountry(state, country){
        state.currentCountry = country
    },


    setCountries(state, countries){
        state.countries = countries.reverse()
    },

    setCountry(state, country) {
        state.countries.push(country)
    },


    // City mutations
    setCurrentCity(state, city){
      state.currentCity = city
    },

    setCities(state, cities) {
      state.cities = cities.reverse()
    },

    setCity(state, city) {
      state.cities.unshift(city)
    },


    setGeneratedCodes(state, generatedCodes){
      state.generatedCodes = generatedCodes
    },

    setGeneratedCode(state, generatedCode){
      state.generatedCodes.push(generatedCode)
    },

    setCurrentGeneratedCode(state, currentGeneratedCode){
      state.currentGeneratedCode = currentGeneratedCode
    },


    setPaymentDegrees(state, paymentDegrees){
      state.paymentDegrees = paymentDegrees
    },

    setPaymentDegree(state, paymentDegree){
      let payload = state.paymentDegrees.pop()
      payload.ongoing = false
      state.paymentDegrees.push(payload)
      state.paymentDegrees.push(paymentDegree) 
    },

    setCurrentPaymentDegree(state, currentPaymentDegree){
      state.currentPaymentDegree = currentPaymentDegree
    },


    setRewardRanges(state, rewardRanges){
      state.rewardRanges = rewardRanges
    },

    setRewardRange(state, rewardRange){
      let payload = state.rewardRanges.pop()
      payload.ongoing = false
      state.rewardRanges.push(payload)
      state.rewardRanges.push(rewardRange)
    },

    setCurrentRewardRange(state, currentRewardRange){
      state.currentRewardRange = currentRewardRange
    },


  },
  actions: {

    // Auth actions
    logout({commit}){
      commit('logout', localStorage.getItem('token'))
    },


    // Member actions
    async setMembers({commit}) {
      await api_util.getMembers().then(response => {
          commit('setMembers', response.data)
        })
    },


    setMember({commit}, member){
      commit('setMember', member)
    },


    async setInscriptions({commit}) {
      await api_util.getInscriptions().then(response => {
          commit('setInscriptions', response.data)
        })
    },


    setInscription({commit}, inscription){
      commit('setInscription', inscription)
    },

    // Country actions
    setCountry({commit}, country){
        commit('setCountry', country)
    },


    setCity({commit}, city){
      commit('setCity', city)
    },


    // Office actions
    setBureau({commit}, country){
      commit('setBureau', country)
  },
    
  },
  modules: {
  }
})
