<template>
    <section class="text-center scroll-smooth py-7">
        <!-- Heading start -->
        <header class="text-center mx-auto mb-5 lg:px-20">
            <h2 class="text-3xl leading-normal mb-2 font-bold text-green-600">How to make profit</h2>
            <p class="text-gray-500 leading-relaxed font-light text-xl mx-auto">HSI offers multiple ways to make profit !</p>
        </header>

        <hr class="h-1 bg-yellow-500 w-20 mx-auto mb-3">
        
        <div class="grid  grid-cols-1 md:grid-cols-3 gap-3 mx-1 md:mx-24">
        <!-- <carousel :settings="settings" :breakpoints='breakpoints' class="md:px-16 mx-4 md:mx-6"> -->
            <div v-for="bonus in bonuses" :key="bonus">
                <div class="py-4 rounded-md mx-0 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2" data-wow-duration="1s" style="visibility: visible; animation-duration: 1s; animation-name: fadeInUp;">
                    <!-- service block -->
                    <div  class="py-2 px-4 mb-0 bg-gray-50 transform transition duration-300 ease-in-out hover:-translate-y-2">
                        <div class="inline-block text-gray-900 mb-1 border-b border-gray-100">
                            <!-- icon -->
                            <h1 class="text-2xl font-bold text-green-500">{{bonus.percent}}%</h1>
                        </div>
                        <h3 class="text-lg leading-normal mb-1 font-semibold text-black">{{bonus.name}}</h3>
                        <p class="text-gray-500">{{bonus.description}}</p>
                    </div>
                    <!-- end service block -->
                </div>
            </div>
        </div>
            <!-- <template #addons>
                <navigation class="bg-gray-50"/>
            </template>  -->
        <!-- </carousel> -->
            
        <div class="grid grid-cols-2 md:grid-cols-5 m-5 md:mx-20 text-center">
            <header class="text-center mx-auto mb-5 lg:px-20 col-span-2 md:col-span-5">
                <h2 class="text-3xl leading-normal mb-2 font-bold text-green-600">Rewards</h2>
                <p class="text-gray-500 leading-relaxed font-light text-xl mx-auto mb-5">Beautiful rewards for Health Success International qualified members !</p>
            <hr class="h-1 bg-yellow-500 w-20 mx-auto mb-3">
            </header>
                <div v-for="rew in rewards" :key="rew" class="mb-6  md:mb-0 col-span-2 md:col-span-1 rounded-md mx-2 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2" data-wow-duration="1s" style="visibility: visible; animation-duration: 1s; animation-name: fadeInUp;">
                    <!-- service block -->
                    <div  class="mb-0 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                        <img :src="rew.image" class="w-full h-full m-0 rounded-t-3xl" :alt="rew.name">
                        <div class="my-3">
                            <h3 class="text-md leading-normal font-semibold text-black mb-2">{{rew.name}}</h3>
                            <p class="text-gray-500 text-sm leading-none">{{rew.description}}</p>
                        </div>
                    </div>
                    <!-- end service block -->
                </div>
        </div>
        <!-- </div> -->

    </section>
</template>

<script>

export default {
    data:()=>({
        settings:{
            itemsToShow: 1,
            autoplay: 2500,
            wrapAround: true
        },  
        breakpoints: {
            // 700px and up
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            500: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            600: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            700: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
            // 1024 and up,
        },
        bonuses:[
            {percent:'25',name:"Referral bonus",description:'The Referral Bonus is a direct bonus calculated and given to the sponsor from the package-ordered value of the one who is refered to join the company by that sponsor.'},
            {percent:'12.5',name:'Binary bonus',description:'The Balancing Bonus is available when the member has two persons below his position. One on his left, an other on his right. 12.5% are calculated on the leg which has a weakest value.'},
            {percent:'20',name:'Recommendation bonus',description:'The Recommandation Binus is calculated on the amount of the patient’s bill after checkup, medical examination.'},
            {percent:'10',name:'Treatment referral bonus',description:'The Traitment referral Bonus is 10% of the amount on the bill of the patient.'},
            {percent:'10',name:'Leadership bonus',description:'The leadership Bonus is 10 % of the available  reward achieved  by the member..'},
            {percent:'100',name:'Shopping bonus',description:'The Shopping Bonus is 100 % of total amount payed by the member in the shop while buying products. It means that, the member receives equivalent products of that amount.'},
        ],
        rewards:[
            {image:'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/AwardsImgs%2FIphone.png?alt=media&token=8b1b35f4-6ab6-4e1e-ab3d-4543bce92e71',
            name:'Start Leader',description:'Get a luxury smart phone when you reach 1000PV-PF.'},
            {image:'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/AwardsImgs%2FHaujueMotor.png?alt=media&token=33115ca2-b091-480f-b493-e2a4ba413014',
            name:'Ruby',description:'Get a luxury motorcycle when you reach 10000PV-PF.'},
            {image:'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/AwardsImgs%2Fcar.png?alt=media&token=2f8b1553-40ab-4c44-b71d-2f404eec0232',
            name:'Saphire',description:'Get a car when you reach 40000PV-PF.'},
            {image:'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/AwardsImgs%2Fcar-lux.jpeg?alt=media&token=6d12edd1-1e90-4cb1-909b-e8b698e86b46',
            name:'Diamond 1',description:'Get a luxury car when you reach 100000PV-PF.'},
            {image:'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/AwardsImgs%2Fhouse.png?alt=media&token=b2f95f7e-155f-4292-9775-042d584da03a',
            name:'Diamond Leader',description:'Get a house when you reach 500000PV-PF.'},
        ],
    })
}
</script>