<template id="news">
    <div class="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden" id="modal">
          <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-900 opacity-75" />
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
            <div class=" p-5 inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="text-right content-end" @click="toggleModal()">
                    <button class="text-right  rounded-3xl text-red-500 hover:bg-red-500 hover:text-white mr-2">
                         <XCircleIcon class="w-6 h-6 text-right"/>
                    </button>
                </div>
                
                <img :src="modal.image" class="w-auto h-auto mx-auto rounded-t-md rounded-b-3xl" alt="">
                <span class="text-left font-black">{{modal.title}}</span>
                <p class="text-sm my-2">
                    {{modal.content}}
                </p>
                <div class="text-right">
                    <span class="text-right text-xs text-grey-300">{{modal.date}}</span>
                </div>
            </div>

          </div>
    </div>
    <section class="mb-7 bg-white">
        <!-- Heading start -->
        <header class="text-center mx-auto mb-2 lg:px-20">
            <h2 class="text-3xl leading-normal mb-2 font-bold text-green-600">Health care focus</h2>
            <p class="text-gray-500 leading-relaxed font-light text-xl mx-auto">If you have a problem in one of these health domains, then you have found solution !</p>
        </header>
        <!-- End heading -->
        
        <hr class="h-1 bg-yellow-500 w-20 mx-auto mb-8">

        <carousel v-bind="settings" :breakpoints='breakpoints' class="md:px-28 mx-4 md:mx-6">
            <slide v-for="slide in domains" :key="slide">
                <div class="respo rounded-md mx-2 bg-gray-50 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2"
                    data-wow-duration="3s" style="visibility: visible; animation-duration: 1s; animation-name: fadeInUp;">
                    <!-- service block -->
                    <div class="elmt">
                        <div>
                            <div v-bind:style="{ 'background-image': 'url(' + slide.image + ')' }" class="w-auto h-40 bg-cover mx-auto rounded-t-md rounded-b-3xl bg-['slide.image']" alt="">
                            </div>
                            <h3 class="text-lg leading-normal mb-2 px-3 font-semibold text-black text-center">
                                {{slide.titre.substring(0, 20)}}...
                            </h3>
                            <p class="text-gray-500 px-3 text-sm text-left">
                               {{slide.content.substring(0, 50)}}...
                            </p>
                        </div>
                        <div class="text-left px-3">
                                <div class="font-light text-xs my-2">
                                    <span>{{slide.date}}</span>
                                </div>
                                <div class="">
                                    <button @click="toggleModal(slide.titre,slide.content,slide.image)" class="mx-auto bg-green-600 text-xs text-white px-3 py-2 rounded-md">
                                        Read more
                                    </button>
                                </div>
                        </div>
                        
                    </div>
                    <!-- end service block -->
                </div>
            </slide>
            <template #addons>
                <navigation/>
            </template>
        </carousel>

    </section>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import {XCircleIcon} from '@heroicons/vue/solid'

export default {
    name:'newsSection',
    components: { 
        Carousel,
        Slide,
        XCircleIcon,
        Navigation,
    },
    data:()=>({
        domains:[
            {
                titre:'Sexual and reproductive health',
                content:"Good sexual and reproductive health is a state of complete physical, mental and social well-being in all matters relating to the reproductive system. It implies that people are able to have a satisfying and safe sex life, the capability to reproduce and the freedom to decide if, when, and how often to do so.",
                image: 'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/health-Domains%2FSexualDomain.png?alt=media&token=27033bd7-bbef-4ae9-92ba-cb0670cd9b4b'
            },
            {titre:'Bone health',content:"Protecting your bone health is easier than you think. Understand how diet, physical activity and other lifestyle factors can affect your bone mass. Bones play many roles in the body — providing structure, protecting organs, anchoring muscles and storing calcium. While it's important to build strong and healthy bones during childhood and adolescence, you can take steps during adulthood to protect bone health, too.(Mayo clinic).",image:'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/health-Domains%2FBones-health.jpeg?alt=media&token=e6758c12-c355-46f9-9c9b-54bcf8277e94'},
            {titre:'Detoxification',content:"Basically, detoxification means cleansing the blood. This is done by removing impurities from the blood in the liver, where toxins are processed for elimination. The body also eliminates toxins through the kidneys, intestines, lungs, lymphatic system, and skin during a body detox. However, when these systems are compromised, impurities aren't properly filtered and the body is adversely affected.",image:'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/health-Domains%2FDetox.jpg?alt=media&token=20ed330f-fc1f-4705-b6a9-f3d96664fca5'},
            {titre:'Skin Care ( Burns & damages on the skin)',content:"Burns are one of the most common household injuries, especially among children. The term “burn” means more than the burning sensation associated with this injury. Burns are characterized by severe skin damage that causes the affected skin cells to die. More serious burns require immediate emergency medical care to prevent complications and death.",image:'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/health-Domains%2FSkin.webp?alt=media&token=fcca4674-a951-4031-bb62-737c2ec20ad5'},
            {titre:'Slimming',content:"Being overweight or obese can lead to a range of health problems. Although many different “fad” diets are available, a balanced lifestyle and nutritious diet are the key to healthful living and better weight control.",image:'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/health-Domains%2FSlimming.jpeg?alt=media&token=aa135333-1c42-4c23-9dec-0b01c93bb2e6'},
            {titre:'Immune system',content:"The immune system is a collection of organs, tissues, cells, and enzymes all united under one goal: protect the body. The immune system needs energy and a variety of nutrients to produce a functional immune response. Specific nutrients impact the physiological mechanisms involved in the inflammatory response, such as the recruitment of immune cells and chemical mediators that communicate between cells in the immune system.",image:'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/health-Domains%2FImmuneSystem.jpeg?alt=media&token=8a6bac0b-bba6-4b77-ba8b-214bac3fe292'},
            {titre:'Mental health care',content:"Mental health includes our emotional, psychological, and social well-being. It affects how we think, feel, and act. It also helps determine how we handle stress, relate to others, and make choices. Mental health is important at every stage of life, from childhood and adolescence through adulthood. Mental health problems are common but help is available. People with mental health problems can get better and many recover completely.",image:'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/health-Domains%2FMental.jpeg?alt=media&token=57d9f3b9-2b0c-4e65-960b-30c28f1029ad'}, 
            ],
        modal:[{title:'title',content:'....',date:'-'}],
        settings:{
                itemsToShow: 1,
                autoplay: 5000,
                wrapAround: true,
                snapAlign: 'center',
        },  
        breakpoints: {
                // 700px and up
                0: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                500: {
                    itemsToShow: 2,
                    snapAlign: 'center',
                },
                600: {
                    itemsToShow: 3,
                    snapAlign: 'center',
                },
                700: {
                    itemsToShow: 4,
                    snapAlign: 'center',
                },
                // 1024 and up,
            },
    }),
    methods:{
        toggleModal(title='-',content="....",image='image') {
            document.getElementById('modal').classList.toggle('hidden');
            this.modal.title=title;
            this.modal.content=content;
            this.modal.image=image
        }
    }
}
</script>
<style>

</style>