import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
// portal route
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home'
    }
  },
// login route
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    meta: {
      title: 'Login'
    }
  },

  /**
   * Member management routers for Local Bureau
   */
  {
    path: '/office/local',
    name: 'localBureau',
    component: () => import('../views/member_management/LocalBureauView.vue'),
    meta: {
      title: 'Local Bureau'
    },
    children: [
      {
        path: '',
        name: 'localBureauHome',
        component: () => import('../views/member_management/LocalBureauHome.vue'),
      },
      {
        path: '/office/local/member',
        name: 'localBureauMember',
        component: () => import('../views/member_management/MemberListCard.vue'),
      }
    ]
  },

  /**
   * Central office routers
   */
  {
    path: '/office/central',
    name: 'central-office',
    component: () => import('../views/central_office/CentralOffice.vue'),
    meta: {
      title: 'Central - Office'
    },
    children: [
      {
        path: '',
        name: 'central-office-home',
        component: () => import('../views/central_office/HomeView.vue'),
      },
      {
        path: '/office/central/location',
        name: 'location',
        component: () => import('../views/central_office/LocationView.vue'),
      },
      {
        path: '/office/central/office',
        name: 'central-office-office',
        component: () => import('../views/central_office/OfficeView.vue'),
      },
      {
        path: '/office/central/profile',
        name: 'central-office-profile',
        component: () => import('../views/central_office/ProfileView.vue'),
      },
      {
        path: '/office/central/settings',
        name: 'central-office-settings',
        component: () => import('../views/central_office/SettingsView.vue'),
      },
      {
        path: '/office/central/member',
        name: 'central-office-member',
        component: () => import('../views/member_management/MemberListCard.vue'),
      },
    ]
  },



  /**
   * Client Side routers
   */
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/client_side/ClientSideView.vue'),
    meta: {
      title: 'Account'
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('../views/client_side/DashboardView.vue'),
        meta: {
          title: 'Account - Dashboard'
        },
      },
      {
        path: '/account/team',
        name: 'accountTeam',
        component: () => import('../views/client_side/TeamView.vue'),
        meta: {
          title: 'Account - Team'
        },
      },
      {
        path: '/account/network',
        name: 'accountNetwork',
        component: () => import('../views/client_side/NetworkView.vue'),
        meta: {
          title: 'Account - Network'
        },
      },
      {
        path: '/account/bonuse',
        name: 'accountBonus',
        component: () => import('../views/client_side/BonusView.vue'),
        meta: {
          title: 'Account - Bonus'
        },
      },
      {
        path: '/account/profil',
        name: 'accountProfil',
        component: () => import('../views/client_side/MemberProfilView.vue'),
        meta: {
          title: 'Account - Profil'
        },
      },
      {
        path: '/account/settings',
        name: 'accountSettings',
        component: () => import('../views/client_side/MemberSettingsView.vue'),
        meta: {
          title: 'Account - Profil'
        },
      }
    ]
  },

 
]

const router = createRouter({ 
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  hashbang: false
})

router.afterEach((to) => {
  document.title = 'HSI | '+to.meta.title
})

export default router
