<template>
    <div>
        <!-- Navbar goes here -->
		<nav class="bg-white shadow-lg">
			<div class="px-3 mx-auto">
				<div class="flex justify-between">

                    <!-- Website Logo -->
                    <a href="/" class="flex items-center py-2 pr-2 hover:text-green-600">
                        <img src="@/assets/health_succes_logo.png" alt="Logo" class="h-8 w-8 mr-2">
                        <span class="text-lg font-thin hover:underline">
                            HSI
                        </span>
                    </a>
					
                    <!-- NavBar items -->
					<div class="hidden md:flex items-center space-x-16">
						<!-- Primary Navbar items -->
						<div class="hidden md:flex items-center space-x-5" id="menu">
							<a @click="scroll('home')"  class="cursor-pointer home py-3 px-2  text-gray-500 border-b-4 border-green-500">Home</a>
                            <a @click="scroll('news')" class="cursor-pointer news py-3 px-2 text-gray-500 hover:text-green-500 transition duration-300">Domains</a>
							<a @click="scroll('about')" class="cursor-pointer about py-3 px-2 text-gray-500 hover:text-green-500 transition duration-300">About us</a>
                            <a @click="scroll('products')" class="cursor-pointer products py-3 px-2 text-gray-500 hover:text-green-500 transition duration-300">Our products</a>
                            <a @click="scroll('bonus')" class="cursor-pointer bonus py-3 px-2 text-gray-500 hover:text-green-500 transition duration-300">Make profit</a>
							<a @click="scroll('contact')" class="cursor-pointer contact py-3 px-2 text-gray-500 hover:text-green-500 transition duration-300">Contact us</a>
						</div>
					</div>
					<!-- Mobile menu button -->
					<div class="md:hidden flex items-center">
						<button class="outline-none mobile-menu-button" @click="hideMenu">
                            <svg class=" w-6 h-6 text-gray-500 hover:text-green-500 "
                                x-show="!showMenu"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path d="M4 6h16M4 12h16M4 18h16"></path>
                            </svg>
		</button>
					</div>
				</div>
			</div>
			<!-- mobile menu -->
			<div class="md:hidden" v-if="isMobile">
				<ul>
					<li class="cursor-pointer block text-sm px-2 py-4 hover:bg-green-500 transition duration-300" @click="scroll('home')">
                        Home
                    </li>
					<li class="cursor-pointer block text-sm px-2 py-4 hover:bg-green-500 transition duration-300" @click="scroll('news')">
                        Domains
                    </li>
					<li class="cursor-pointer block text-sm px-2 py-4 hover:bg-green-500 transition duration-300" @click="scroll('about')">
                        About us
                    </li>
                    <li class="cursor-pointer block text-sm px-2 py-4 hover:bg-green-500 transition duration-300" @click="scroll('products')">
                        Our products
                    </li>
                    <li class="cursor-pointer block text-sm px-2 py-4 hover:bg-green-500 transition duration-300" @click="scroll('bonus')">
                        Make profit
                    </li>
					<li class="cursor-pointer block text-sm px-2 py-4 hover:bg-green-500 transition duration-300" @click="scroll('contact')">
                        Contact Us
                    </li>
				</ul>
			</div>
		</nav>
    </div>
</template>


<script>
export default {
    name: 'coverPageNavBar',
    data(){
        return{
            isMobile: false
        }
    },
    methods: {
        hideMenu(){
            if(this.isMobile){
                this.isMobile=false
            }else{
                this.isMobile=true
            }
        },
        scroll(id) {  
            // 
            document.getElementById(id).scrollIntoView({
                behavior: "smooth"
            });
            // let element = document.getElementById("menu").querySelector("a:active")
            // let list = element.classList;
            // list.remove("border-b-4");
            // list.remove("border-green-500");

            let elt=document.getElementsByTagName('a')
            for (let i = 0; i < elt.length; i++) {
                const list = elt[i].classList;
                list.remove("border-b-4");
                list.remove("border-green-500");  
                list.remove("text-green-500");
            }
            

            let element=document.getElementsByClassName(id)
            let li = element[0].classList;
            li.add("border-b-4");
            li.add("border-green-500");
            li.add("text-green-500");
        }
    }
    
}
</script>