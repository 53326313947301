/** Import des modules nécessaires */

import axios from "axios"
import { accountService } from "@/_services"


import router from "@/router"
import store from "@/store"



const Axios = axios.create({
    // baseURL: 'http://localhost:8000/api/v01/'
    baseURL: 'https://healthsuccessinternationalapi.onrender.com/api/v01/'
})

/** 
 * interceper all axios requests to add token
*/
Axios.interceptors.request.use(request => {
    // add token in request header if the user is connected
    let token = accountService.getToken()
    if (token) {
        request.headers.Authorization = 'Bearer ' + token
    }

    return request
})

/** 
 * interceper all api responses to manage errors
*/
Axios.interceptors.response.use(response => {
    // store.commit('setIsUserLoggin', true)
    return response
}, error => {
    if (error.response.status === 401) {
        // store.commit('setIsUserLoggin', false)
        accountService.logout()
        router.push({ name: 'home' })
    } else if ((error.response.status === 400) || (error.response.status === 500)) {
        store.commit('setUsernameCreatedError', true)
    }
})

export default Axios