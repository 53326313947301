import { library } from '@fortawesome/fontawesome-svg-core'
import { faHatWizard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const globalOptions = {
  mode: 'international',
  invalidMsg: 'Invalid number',
  autocomplete: 'off'
};

const options = {
  transition: "Vue-Toastification__fade",
  maxToasts: 20,
  newestOnTop: true
}


library.add(faHatWizard)

createApp(App)
.use(store)
.use(router)
.use(Toast, options)
.use(VueTelInput, globalOptions)
.component('font-awesome-icon', FontAwesomeIcon)
.component('Datepicker', Datepicker)
.mount('#app')

