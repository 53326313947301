<template>
    <div class="fixed z-10 overflow-y-scroll top-0 w-full left-0 hidden" id="product">
          <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 bg-gray-900 opacity-75" />
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
            <div class=" p-5 inline-block align-center bg-white rounded-lg text-left overflow-scroll shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="text-right content-end" @click="toggleModal()">
                    <button class="text-right  rounded-3xl text-red-500 hover:bg-red-500 hover:text-white mr-2">
                         <XCircleIcon class="w-6 h-6 text-right"/>
                    </button>
                </div>
                    <img :src="modalO.image" class="w-64  h-55  mx-auto rounded-3xl" alt="">
                    <span class="text-left font-black">{{modalO.name}}</span>
                    <p class="text-sm my-2">
                        {{modalO.description}}
                    </p>
            </div>
            </div>
    </div>
    <!-- component -->
<div class="section relative md:pb-0 bg-white">
    <div class="xl:max-w-6xl mx-auto">
        <!-- Heading sta rt -->
        <header class="text-center mx-auto lg:px-20">
            <h2 class="text-3xl leading-normal mb-2 font-bold text-green-600">Our products</h2>
            <p class="text-gray-500 leading-relaxed font-light text-xl mx-auto pb-2">Natural products for your wellness !</p>
        </header>
        <!-- End heading -->

        <hr class="h-1 bg-yellow-500 w-20 mx-auto mb-3">
        
        <!-- row -->
        <div class="grid grid-cols-1 md:grid-cols-3 py-4 mx-10 flex-row -mx-4 text-left">
            <div v-for=" (prod, name, index) in page" :key="prod" :index="index" @click="toggleModal(prod.name,prod.description,prod.image)">
                <div class="flex-shrink max-w-full w-full lg:px-6 wow fadeInUp" data-wow-duration="1s" style="visibility: visible; animation-duration: 1s; animation-name: fadeInUp;">
                    <!-- service block -->
                    <div  class="rounded-3xl flex md:mb-12 mb-4 bg-gray-50  border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2">
                        <div class="max-h-full rounded-l-3xl h-100% w-96 bg-cover bg-center m-0" v-bind:style="{ 'background-image': 'url(' + prod.image + ')' }">
                    </div>
                        <div class="m-5">
                            <h3 class="text-lg leading-normal font-semibold text-black">{{prod.name}}</h3>
                            <p class="text-gray-500 leading-none">{{prod.description.substring(0, 80)}}... <span class="text-sm text-blue-600"> read more</span></p>                       
                        </div>
                </div>
                <!-- end service block -->
            </div>
        </div>
        <!-- end row -->
    </div>
    <div class="flex justify-between text-center mx-5">
        <div></div>
        <div></div>
        <div></div>
        <button class="bg-blue-500 text-center text-white px-4 py-2 rounded-2xl shadow-lg" @click="back()" :disabled="start<='0'" >Back</button>
        <div>
            <span class="text-sm text-blue-600">{{start+1}} to {{end+1}} of {{products.length}}</span>     
        </div>    
        
        <button class="bg-blue-500 text-center text-white px-4 py-2 rounded-2xl shadow-lg" @click="next()" :disabled="end>=products.length">Next</button>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
</div>
</template>
<script>
import {XCircleIcon} from '@heroicons/vue/solid'
export default {
    components:{
        XCircleIcon
    },
    mounted(){
      this.page=this.pagination()  
    },
    data(){
        return{
            start:0,
            end:5,
            modalO:[{name:'',description:'',image:''}],
            products:[
                {name:'Slimming Tea',image:'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/Slimming%20Tea.jpg?alt=media&token=7be27952-a206-43bd-88b5-68058483bb84',description:"Slimming tea is like a white tea, a green tea, a weight loss tea, and a greal-tasting herbal tea - all wrapped up in a beauty pouch. Remarkable things happen when you drink 1-2 cups of Slimming Tea daily, It's gentie, yet surprisingly powerful. It is used as a coloncleanse, a parasite cleanse, a Candida cleanse, a blood purifier and a whole body detoxifer. It Supports Metabolism; 2. Boosts Energy; 3. Reduces Bloating; 4. Burns fat; 5. Detoxifying"},
                {name:'Deep Sea Fish Oil',image:'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/Golden%20Alaska.webp?alt=media&token=b013cb08-1557-404a-b707-a50556261270',description:"Deep Sea Fish Oil contains essential Omega-3 fatty acids, EPA, and DHA. In addition to being widely recognized for its many health benefits, fish oil is also known for its strength to reduce inflammation and help counteract the effects of aging. EPA and DHA are the two most important fatty acids that play a very crucial role in the development of the brain. EPA is a substance in the body called prostaglandins, which controls blood clotting and other arterial functions. Omega-3 fatty acids provide significant reductions in triglyceride levels and increases in good HDL cholesterol. Of almost equal importance is DHA, a major component of the human brain tissues and the retinal tissue of the eyes. DHA also serves the other important function of the transmission of nerve impulses in the nervous system"},
                {name:'Little Koala Milk Calcium',image:'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/Little%20Koala%20Milk%20Calcium%20with%20Probiotic.jpg?alt=media&token=10f165c8-201e-47d0-a7f3-fd88ea03a616',description:'Using 100% Australian cow’s milk combined with probiotic (Lactobacillus) to support a healthy digestive system, Little Koala Milk Calcium with Probiotic is made in an Australian TGA licensed facility guaranteeing you only the best product.  With full ingredient and manufacturing traceability the products quality is assured.  Help your child to a better start in life with Little Koala Great tasting Koala shaped chewable tablets.'},
                {name:'Fertility Tea',image:'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/FertilityTea.jpg?alt=media&token=ac582fdd-959e-4597-84ae-a3c5cc0725ad',description:"A Tea for preconception Fertility is a delicious, designed to increase your chance of conceiving by bringing together many herbs known to promote female reproductive wellness and hormone balance. An all - natural herbal tea to support female reproductive health and fertility, Doctor designed for women who are trying to conceive, special fertility blend of organic and wildcrafted herbs"},
                {name:'Men Power Energy Coffee',image:'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/X-PowerCoffee.jpg?alt=media&token=458daf48-ab6a-4da7-a8eb-f659a92e8d3b',description:'WST men Power energy coffee, formulated with Epimedium, Maca and TongkatAli and Gingseng is a super drink allowing you to boost your energy , enhance your sexual performance and improve sperm quality with a cup of coffee every day. As known, that Epimedium, Maca and Tonkat Ali are some of the most powerful supplements for men. To make it even better, also combines Gingsengto enhance the benefits of those three three supplements.'},
                {name:'Regular Strength Slimming Tea',image:'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/Regular%20Strength%20Slimming%20Tea.jpg?alt=media&token=1d6cc6bd-c2fb-404b-8036-3a14a5531764',description:'This product has the same characteristics as the slimming tea: It increases the respiratory capacity, Good for asthmatics, Treats and prevents diabetes, Protects the heart, Controls blood pressure, Facilitates weight loss, Prevents and fights anemia, Increases the immune defense, Anti-analgesic and anti-inflammatory, Protects and rehydrates the skin, Improve gastrointestinal function, Prevents the development of cancer, Giving health to the eyes, Reduce menopausal symptoms ...'},
                {name:'L-Carnitine',image:'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/L-Carnitine.jpg?alt=media&token=6e1b94aa-d71e-4558-8f7e-490627d574fd',description:'Carnitirie is a natural product of raw material processing, green tea extract, lotus leaves, microcrystalline celluloses and magnesium stearate. This product is suitable for fighting obesity facilitating weight loss This product is good for the treatment of angina, heart failure, cardiomyopathy and myocardial infarction as well as diabetic neuropathy. Dosage: Take 3 capsules 2 times a day. Morning and evening.'},
                {name:'Bunon Pain Relief',image:'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/Bunon%20Pam%20Relief.jpg?alt=media&token=fa5bd813-4b39-4b13-8da8-91ef4512122b',description:'Improve blood circulation, eliminets joint swelling, pain and stiffness, reduce inflammation, activate immunity, and accelerate the recovery of local physiological function.'},
                {name:'Calcium',image:'https://firebasestorage.googleapis.com/v0/b/health-success-internati-72c80.appspot.com/o/Calcium.jpg?alt=media&token=653b82c7-434e-427c-93a2-fa56cbde4937',description:'Strengthen bone formation, Treats toothaches, Rheumatism problem, Low back pain and back problems, Strengthens spermatozoa, Fighting infertility in men and women ...'},

            ],  
            page:[]
                // page:this.products
            // page:this.pagination(0,6)
        }
    },
    methods:{
        toggleModal(name='Titre',description="Contenus",image='Image') {
            this.modalO.name=name;
            this.modalO.description=description;
            this.modalO.image=image
            document.getElementById('product').classList.toggle('hidden');
        },
        pagination(start=this.start,end=this.end){
            let list=[]
            if (end>this.products.length-1) {
                end=this.products.length-1
                start=end-5
                if (this.products.length<6) {
                    start=0
                }
                this.end=end
                this.start=start
            }
            for (let i = start; i <= end; i++) {
                list.push(this.products[i])
            }
            return list
        },
        next(){
            this.start+=6
            this.end+=6                        
            this.page=this.pagination()
        },
        back(){
            this.start-=6
            this.end-=6 
            if (this.start<0) this.start=0
            if(this.end<=0) this.end=5
            this.page=this.pagination()
        }
    }
}
</script>